.Page1{
    background-image: url(./bgBody.jpg);
    height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Page2{
    background-image: url(./bgBody2.jpg);
    padding : 20vh;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Page3{
    background-image: url(./bgBody2.jpg);
    padding-top: 20vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
  }
  
  .FooterDetails{
    background-color: rgba(0,16,55,255);
    background-image: url(./bgBody2.jpg);
    padding: 3vh;
    height: 18vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
  }
  
  .Footer{
    background-image: url(./bgBody2.jpg);
  }
  
  .custom-link{
    color: #636f8a;
  }
  .custom-link:hover {  
    color: white;
  }
 
  .p1SupTitle{
    line-height: 1;
  }

  .svg_icons{
    transform: scale(3);
  }